import $http from "@/utils/request.js";
import qs from "qs";

//获取云存储临时token 上传
export function getUploadToken(data) {
    return $http.post('/getUploadTempToken', data)
}

//下载，预览
export function downloadToken(data) {
    return $http.get('/getUploadTempToken', {params:data})
}



